import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Plugins } from '@capacitor/core';
import { AlertController, ModalController } from '@ionic/angular';
import { CindeApiService } from 'src/app/services/cinde-api.service';
const { Storage } = Plugins;
@Component({
  selector: 'app-modal',
  templateUrl: './modal.page.html',
  styleUrls: ['./modal.page.scss'],
})
export class ModalPage implements OnInit {
  form: FormGroup;
  
  constructor(private modalCtrl: ModalController,
              private fb: FormBuilder,
              private alertCtrl: AlertController,
              private cindeAPI: CindeApiService) { }

  ngOnInit() {
    this.form = this.fb.group({
      description: ['', [Validators.required]],
    });
  }

  dismiss() {
    this.modalCtrl.dismiss({
      'dismissed': true
    });
  }

  request(){
    this.cindeAPI.requestProfileUpdates(this.form.value).subscribe(async ()=>{
      let alert = await this.alertCtrl.create({
        header: 'Information',
        message: "Your request has been sent",
        buttons: [{
          text: 'OK',
          role: 'OK',
          cssClass: 'secondary',
          handler: () => {
            this.dismiss();
          }
        }]
      });
      alert.present();
    },async (err) => {
      let alert = await this.alertCtrl.create({
        header: 'Error',
        message: "Something goes wrong. Try again in a few minutes",
        buttons: ['OK']
      });
      alert.present();
    });
  }

}
