import { Component, OnInit } from '@angular/core';
import { ModalController, NavController } from '@ionic/angular';
import { CindeApiService } from 'src/app/services/cinde-api.service';
@Component({
  selector: 'app-modal',
  templateUrl: './modal.page.html',
  styleUrls: ['./modal.page.scss'],
})
export class ModalPage implements OnInit {
  workingGroups: any;
  constructor(private modalCtrl: ModalController,
              private cindeApi: CindeApiService,
              private navCtrl: NavController) { }

  async ngOnInit() {
    this.cindeApi.getWorkingGroups().subscribe(res => {
      this.workingGroups = res;
    })
  }

  openWorkGroup(workingGroupId){
    this.dismiss()
    this.navCtrl.navigateForward(`/tabs/working-groups/${workingGroupId}`)
  }

  dismiss() {
    this.modalCtrl.dismiss({
      'dismissed': true
    });
  }
}
