import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Plugins } from '@capacitor/core';
import { AlertController, ModalController } from '@ionic/angular';
import { CindeApiService } from 'src/app/services/cinde-api.service';
const { Storage } = Plugins;
@Component({
  selector: 'app-modal',
  templateUrl: './modal.page.html',
  styleUrls: ['./modal.page.scss'],
})
export class ModalPage implements OnInit {
  form: FormGroup;
  labelDescription: string = "Describe your request";
  categorySelected: boolean = false;
  preSelected;

  constructor(private modalCtrl: ModalController,
              private fb: FormBuilder,
              private alertCtrl: AlertController,
              private cindeAPI: CindeApiService) { }

  ngOnInit() {
    if (this.preSelected){
      this.form = this.fb.group({
        category: ['Request general country information, country benchmark studies, real estate information, specific sector or industry information, customized investment proposals.', [Validators.required]],
        description: ['', [Validators.required]],
      });
      this.categorySelected = true;
    }else{
      this.form = this.fb.group({
        category: ['', [Validators.required]],
        description: ['', [Validators.required]],
      });
    }
  }

  dismiss() {
    this.modalCtrl.dismiss({
      'dismissed': true
    });
  }

  changeDescription(){
    this.categorySelected = true;
    this.labelDescription = this.form.value.category
  }

  request(){
    this.cindeAPI.requestSupport(this.form.value).subscribe(async ()=>{
      let alert = await this.alertCtrl.create({
        header: 'Information',
        message: "Your request has been sent",
        buttons: [{
          text: 'OK',
          role: 'OK',
          cssClass: 'secondary',
          handler: () => {
            this.dismiss();
          }
        }]
      });
      alert.present();
    },async (err) => {
      let alert = await this.alertCtrl.create({
        header: 'Error',
        message: "Something goes wrong. Try again in a few minutes",
        buttons: ['OK']
      });
      alert.present();
    });
  }

}
